import { IconProps } from '@pyxis/react/dist/components/Icons/Icon';
import dynamic from 'next/dynamic';
import React from 'react';

import { PyxisIconType } from './PyxisIconOptions';

export const IconAlarm = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconAlarm),
);
export const IconAndroid = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconAndroid),
);
export const IconApple = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconApple),
);
export const IconArrowDown = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconArrowDown),
);
export const IconArrowLeft = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconArrowLeft),
);
export const IconArrowRight = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconArrowRight),
);
export const IconArrowUp = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconArrowUp),
);
export const IconBaloon = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconBaloon),
);
export const IconBell = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconBell),
);
export const IconBook = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconBook),
);
export const IconBuildings = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconBuildings),
);
export const IconBurger = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconBurger),
);
export const IconCalendarClock = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCalendarClock),
);
export const IconCalendarMail = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCalendarMail),
);
export const IconCalendar = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCalendar),
);
export const IconCamera = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCamera),
);
export const IconCar = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCar),
);
export const IconCardsOverlap = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCardsOverlap),
);
export const IconCart = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCart),
);
export const IconChart = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconChart),
);
export const IconChat = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconChat),
);
export const IconCheckCircle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCheckCircle),
);
export const IconCheckSquare = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCheckSquare),
);
export const IconCheck = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCheck),
);
export const IconChevronCircleDown = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconChevronCircleDown),
);
export const IconChevronCircleLeft = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconChevronCircleLeft),
);
export const IconChevronCircleRight = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconChevronCircleRight),
);
export const IconChevronCircleUp = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconChevronCircleUp),
);
export const IconChevronDown = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconChevronDown),
);
export const IconChevronLeft = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconChevronLeft),
);
export const IconChevronRight = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconChevronRight),
);
export const IconChevronUp = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconChevronUp),
);
export const IconClock24 = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconClock24),
);
export const IconClock = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconClock),
);
export const IconComputer = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconComputer),
);
export const IconCreditCard = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCreditCard),
);
export const IconCrossCircle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCrossCircle),
);
export const IconCrossSquare = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCrossSquare),
);
export const IconCross = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconCross),
);
export const IconDamageThirdParties = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconDamageThirdParties),
);
export const IconDevices = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconDevices),
);
export const IconDiamond = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconDiamond),
);
export const IconDocumentAlert = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconDocumentAlert),
);
export const IconDocumentSigned = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconDocumentSigned),
);
export const IconDocument = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconDocument),
);
export const IconDownload = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconDownload),
);
export const IconEuro = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconEuro),
);
export const IconExclamationCircle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconExclamationCircle),
);
export const IconExclamationTriangle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconExclamationTriangle),
);
export const IconExternalLink = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconExternalLink),
);
export const IconEyeSlashed = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconEyeSlashed),
);
export const IconEye = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconEye),
);
export const IconFacebook = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconFacebook),
);
export const IconFamilyRc = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconFamilyRc),
);
export const IconFax = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconFax),
);
export const IconFloppyDisk = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconFloppyDisk),
);
export const IconFolder = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconFolder),
);
export const IconGear = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconGear),
);
export const IconGift = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconGift),
);
export const IconGrid = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconGrid),
);
export const IconHomeDamageEbike = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeDamageEbike),
);
export const IconHomeDamageFamily = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeDamageFamily),
);
export const IconHomeDamagePets = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeDamagePets),
);
export const IconHomeDamageThirdParties = dynamic(
  import('@pyxis/react').then((module) => module.IconHomeDamageThirdParties),
);
export const IconHomeDomesticThefts = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeDomesticThefts),
);
export const IconHomeEmergenciesRepairs = dynamic(
  import('@pyxis/react').then((module) => module.IconHomeEmergenciesRepairs),
);
export const IconHomeFireExplosion = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeFireExplosion),
);
export const IconHomeFurnitureContent = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeFurnitureContent),
);
export const IconHomeInjuriesFamily = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeInjuriesFamily),
);
export const IconHomeInjuriesFreetime = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeInjuriesFreetime),
);
export const IconHomeInsuranceHome = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeInsuranceHome),
);
export const IconHomeLegalProtection = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeLegalProtection),
);
export const IconHomeNaturalEvents = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeNaturalEvents),
);
export const IconHomeRobberiesOutside = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeRobberiesOutside),
);
export const IconHomeTelephoneLegalAdvice = dynamic(
  import('@pyxis/react').then((module) => module.IconHomeTelephoneLegalAdvice),
);
export const IconHomeTheftValuable = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHomeTheftValuable),
);
export const IconHome = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHome),
);
export const IconHourglass = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconHourglass),
);
export const IconIdCard = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconIdCard),
);
export const IconInfoCircle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconInfoCircle),
);
export const IconInstagram = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconInstagram),
);
export const IconInsuranceHome = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconInsuranceHome),
);
export const IconJobs = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconJobs),
);
export const IconKey = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconKey),
);
export const IconKeyhole = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconKeyhole),
);
export const IconLink = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconLink),
);
export const IconLinkedin = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconLinkedin),
);
export const IconListCircle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconListCircle),
);
export const IconList = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconList),
);
export const IconLoader = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconLoader),
);
export const IconLocalProtection = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconLocalProtection),
);
export const IconLockOpen = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconLockOpen),
);
export const IconLock = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconLock),
);
export const IconLogout = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconLogout),
);
export const IconMagicWand = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMagicWand),
);
export const IconMagnifyingGlass = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMagnifyingGlass),
);
export const IconMail = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMail),
);
export const IconMapMarker = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMapMarker),
);
export const IconMedal = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMedal),
);
export const IconMinusCircle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMinusCircle),
);
export const IconMinusSquare = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMinusSquare),
);
export const IconMinus = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMinus),
);
export const IconMoped = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMoped),
);
export const IconMoreHorizontalCircle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMoreHorizontalCircle),
);
export const IconMoreHorizontal = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMoreHorizontal),
);
export const IconMoreVertical = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMoreVertical),
);
export const IconMotorcycle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconMotorcycle),
);
export const IconOdometer = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconOdometer),
);
export const IconPaperPlane = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPaperPlane),
);
export const IconPaperclip = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPaperclip),
);
export const IconPec = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPec),
);
export const IconPenForm = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPenForm),
);
export const IconPen = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPen),
);
export const IconPhone24 = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPhone24),
);
export const IconPhone = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPhone),
);
export const IconPlate = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPlate),
);
export const IconPlusCircle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPlusCircle),
);
export const IconPlusSquare = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPlusSquare),
);
export const IconPlus = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPlus),
);
export const IconPound = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPound),
);
export const IconPrima = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconPrima),
);
export const IconQuestionCircle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconQuestionCircle),
);
export const IconReload = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconReload),
);
export const IconShieldCheck = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconShieldCheck),
);
export const IconSki = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconSki),
);
export const IconSmartphone = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconSmartphone),
);
export const IconStarFilled = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconStarFilled),
);
export const IconStar = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconStar),
);
export const IconSteeringWheel = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconSteeringWheel),
);
export const IconSwitch = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconSwitch),
);
export const IconTablet = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconTablet),
);
export const IconThumbUp = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconThumbUp),
);
export const IconTrashBin = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconTrashBin),
);
export const IconTshirtSport = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconTshirtSport),
);
export const IconTwitter = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconTwitter),
);
export const IconUmbrella = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconUmbrella),
);
export const IconUpload = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconUpload),
);
export const IconUserCheck = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconUserCheck),
);
export const IconUserCircle = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconUserCircle),
);
export const IconUserCross = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconUserCross),
);
export const IconUserMinus = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconUserMinus),
);
export const IconUserPlus = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconUserPlus),
);
export const IconUser = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconUser),
);
export const IconUsers = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconUsers),
);
export const IconVan = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconVan),
);
export const IconVehicleCollisionKasko = dynamic(
  import('@pyxis/react').then((module) => module.IconVehicleCollisionKasko),
);
export const IconVehicleDriverInjuries = dynamic(
  import('@pyxis/react').then((module) => module.IconVehicleDriverInjuries),
);
export const IconVehicleFullKasko = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconVehicleFullKasko),
);
export const IconVehicleGlasses = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconVehicleGlasses),
);
export const IconVehicleLegalProtection = dynamic(
  import('@pyxis/react').then((module) => module.IconVehicleLegalProtection),
);
export const IconVehicleNaturalEvents = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconVehicleNaturalEvents),
);
export const IconVehicleRicourseWaiver = dynamic(
  import('@pyxis/react').then((module) => module.IconVehicleRicourseWaiver),
);
export const IconVehicleRoadsideAssistance = dynamic(
  import('@pyxis/react').then((module) => module.IconVehicleRoadsideAssistance),
);
export const IconVehicleSecuredBonus = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconVehicleSecuredBonus),
);
export const IconVehicleTheftFire = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconVehicleTheftFire),
);
export const IconVehicleVandalism = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconVehicleVandalism),
);
export const IconWallet = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconWallet),
);
export const IconWhatsapp = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconWhatsapp),
);
export const IconWrench = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconWrench),
);
export const IconYoutube = dynamic(() =>
  import('@pyxis/react').then((module) => module.IconYoutube),
);

export type PyxisIconProps = {
  name: PyxisIconType;
} & IconProps;

export const PyxisIcon = ({ name, ...rest }: PyxisIconProps) => {
  switch (name) {
    case 'IconAlarm':
      return <IconAlarm {...rest} />;
    case 'IconAndroid':
      return <IconAndroid {...rest} />;
    case 'IconApple':
      return <IconApple {...rest} />;
    case 'IconArrowDown':
      return <IconArrowDown {...rest} />;
    case 'IconArrowLeft':
      return <IconArrowLeft {...rest} />;
    case 'IconArrowRight':
      return <IconArrowRight {...rest} />;
    case 'IconArrowUp':
      return <IconArrowUp {...rest} />;
    case 'IconBaloon':
      return <IconBaloon {...rest} />;
    case 'IconBell':
      return <IconBell {...rest} />;
    case 'IconBook':
      return <IconBook {...rest} />;
    case 'IconBuildings':
      return <IconBuildings {...rest} />;
    case 'IconBurger':
      return <IconBurger {...rest} />;
    case 'IconCalendarClock':
      return <IconCalendarClock {...rest} />;
    case 'IconCalendarMail':
      return <IconCalendarMail {...rest} />;
    case 'IconCalendar':
      return <IconCalendar {...rest} />;
    case 'IconCamera':
      return <IconCamera {...rest} />;
    case 'IconCar':
      return <IconCar {...rest} />;
    case 'IconCardsOverlap':
      return <IconCardsOverlap {...rest} />;
    case 'IconCart':
      return <IconCart {...rest} />;
    case 'IconChart':
      return <IconChart {...rest} />;
    case 'IconChat':
      return <IconChat {...rest} />;
    case 'IconCheckCircle':
      return <IconCheckCircle {...rest} />;
    case 'IconCheckSquare':
      return <IconCheckSquare {...rest} />;
    case 'IconCheck':
      return <IconCheck {...rest} />;
    case 'IconChevronCircleDown':
      return <IconChevronCircleDown {...rest} />;
    case 'IconChevronCircleLeft':
      return <IconChevronCircleLeft {...rest} />;
    case 'IconChevronCircleRight':
      return <IconChevronCircleRight {...rest} />;
    case 'IconChevronCircleUp':
      return <IconChevronCircleUp {...rest} />;
    case 'IconChevronDown':
      return <IconChevronDown {...rest} />;
    case 'IconChevronLeft':
      return <IconChevronLeft {...rest} />;
    case 'IconChevronRight':
      return <IconChevronRight {...rest} />;
    case 'IconChevronUp':
      return <IconChevronUp {...rest} />;
    case 'IconClock24':
      return <IconClock24 {...rest} />;
    case 'IconClock':
      return <IconClock {...rest} />;
    case 'IconComputer':
      return <IconComputer {...rest} />;
    case 'IconCreditCard':
      return <IconCreditCard {...rest} />;
    case 'IconCrossCircle':
      return <IconCrossCircle {...rest} />;
    case 'IconCrossSquare':
      return <IconCrossSquare {...rest} />;
    case 'IconCross':
      return <IconCross {...rest} />;
    case 'IconDamageThirdParties':
      return <IconDamageThirdParties {...rest} />;
    case 'IconDevices':
      return <IconDevices {...rest} />;
    case 'IconDiamond':
      return <IconDiamond {...rest} />;
    case 'IconDocumentAlert':
      return <IconDocumentAlert {...rest} />;
    case 'IconDocumentSigned':
      return <IconDocumentSigned {...rest} />;
    case 'IconDocument':
      return <IconDocument {...rest} />;
    case 'IconDownload':
      return <IconDownload {...rest} />;
    case 'IconEuro':
      return <IconEuro {...rest} />;
    case 'IconExclamationCircle':
      return <IconExclamationCircle {...rest} />;
    case 'IconExclamationTriangle':
      return <IconExclamationTriangle {...rest} />;
    case 'IconExternalLink':
      return <IconExternalLink {...rest} />;
    case 'IconEyeSlashed':
      return <IconEyeSlashed {...rest} />;
    case 'IconEye':
      return <IconEye {...rest} />;
    case 'IconFacebook':
      return <IconFacebook {...rest} />;
    case 'IconFamilyRc':
      return <IconFamilyRc {...rest} />;
    case 'IconFax':
      return <IconFax {...rest} />;
    case 'IconFloppyDisk':
      return <IconFloppyDisk {...rest} />;
    case 'IconFolder':
      return <IconFolder {...rest} />;
    case 'IconGear':
      return <IconGear {...rest} />;
    case 'IconGift':
      return <IconGift {...rest} />;
    case 'IconGrid':
      return <IconGrid {...rest} />;
    case 'IconHomeDamageEbike':
      return <IconHomeDamageEbike {...rest} />;
    case 'IconHomeDamageFamily':
      return <IconHomeDamageFamily {...rest} />;
    case 'IconHomeDamagePets':
      return <IconHomeDamagePets {...rest} />;
    case 'IconHomeDamageThirdParties':
      return <IconHomeDamageThirdParties {...rest} />;
    case 'IconHomeDomesticThefts':
      return <IconHomeDomesticThefts {...rest} />;
    case 'IconHomeEmergenciesRepairs':
      return <IconHomeEmergenciesRepairs {...rest} />;
    case 'IconHomeFireExplosion':
      return <IconHomeFireExplosion {...rest} />;
    case 'IconHomeFurnitureContent':
      return <IconHomeFurnitureContent {...rest} />;
    case 'IconHomeInjuriesFamily':
      return <IconHomeInjuriesFamily {...rest} />;
    case 'IconHomeInjuriesFreetime':
      return <IconHomeInjuriesFreetime {...rest} />;
    case 'IconHomeInsuranceHome':
      return <IconHomeInsuranceHome {...rest} />;
    case 'IconHomeLegalProtection':
      return <IconHomeLegalProtection {...rest} />;
    case 'IconHomeNaturalEvents':
      return <IconHomeNaturalEvents {...rest} />;
    case 'IconHomeRobberiesOutside':
      return <IconHomeRobberiesOutside {...rest} />;
    case 'IconHomeTelephoneLegalAdvice':
      return <IconHomeTelephoneLegalAdvice {...rest} />;
    case 'IconHomeTheftValuable':
      return <IconHomeTheftValuable {...rest} />;
    case 'IconHome':
      return <IconHome {...rest} />;
    case 'IconHourglass':
      return <IconHourglass {...rest} />;
    case 'IconIdCard':
      return <IconIdCard {...rest} />;
    case 'IconInfoCircle':
      return <IconInfoCircle {...rest} />;
    case 'IconInstagram':
      return <IconInstagram {...rest} />;
    case 'IconInsuranceHome':
      return <IconInsuranceHome {...rest} />;
    case 'IconJobs':
      return <IconJobs {...rest} />;
    case 'IconKey':
      return <IconKey {...rest} />;
    case 'IconKeyhole':
      return <IconKeyhole {...rest} />;
    case 'IconLink':
      return <IconLink {...rest} />;
    case 'IconLinkedin':
      return <IconLinkedin {...rest} />;
    case 'IconListCircle':
      return <IconListCircle {...rest} />;
    case 'IconList':
      return <IconList {...rest} />;
    case 'IconLoader':
      return <IconLoader {...rest} />;
    case 'IconLocalProtection':
      return <IconLocalProtection {...rest} />;
    case 'IconLockOpen':
      return <IconLockOpen {...rest} />;
    case 'IconLock':
      return <IconLock {...rest} />;
    case 'IconLogout':
      return <IconLogout {...rest} />;
    case 'IconMagicWand':
      return <IconMagicWand {...rest} />;
    case 'IconMagnifyingGlass':
      return <IconMagnifyingGlass {...rest} />;
    case 'IconMail':
      return <IconMail {...rest} />;
    case 'IconMapMarker':
      return <IconMapMarker {...rest} />;
    case 'IconMedal':
      return <IconMedal {...rest} />;
    case 'IconMinusCircle':
      return <IconMinusCircle {...rest} />;
    case 'IconMinusSquare':
      return <IconMinusSquare {...rest} />;
    case 'IconMinus':
      return <IconMinus {...rest} />;
    case 'IconMoped':
      return <IconMoped {...rest} />;
    case 'IconMoreHorizontalCircle':
      return <IconMoreHorizontalCircle {...rest} />;
    case 'IconMoreHorizontal':
      return <IconMoreHorizontal {...rest} />;
    case 'IconMoreVertical':
      return <IconMoreVertical {...rest} />;
    case 'IconMotorcycle':
      return <IconMotorcycle {...rest} />;
    case 'IconOdometer':
      return <IconOdometer {...rest} />;
    case 'IconPaperPlane':
      return <IconPaperPlane {...rest} />;
    case 'IconPaperclip':
      return <IconPaperclip {...rest} />;
    case 'IconPec':
      return <IconPec {...rest} />;
    case 'IconPenForm':
      return <IconPenForm {...rest} />;
    case 'IconPen':
      return <IconPen {...rest} />;
    case 'IconPhone24':
      return <IconPhone24 {...rest} />;
    case 'IconPhone':
      return <IconPhone {...rest} />;
    case 'IconPlate':
      return <IconPlate {...rest} />;
    case 'IconPlusCircle':
      return <IconPlusCircle {...rest} />;
    case 'IconPlusSquare':
      return <IconPlusSquare {...rest} />;
    case 'IconPlus':
      return <IconPlus {...rest} />;
    case 'IconPound':
      return <IconPound {...rest} />;
    case 'IconPrima':
      return <IconPrima {...rest} />;
    case 'IconQuestionCircle':
      return <IconQuestionCircle {...rest} />;
    case 'IconReload':
      return <IconReload {...rest} />;
    case 'IconShieldCheck':
      return <IconShieldCheck {...rest} />;
    case 'IconSki':
      return <IconSki {...rest} />;
    case 'IconSmartphone':
      return <IconSmartphone {...rest} />;
    case 'IconStarFilled':
      return <IconStarFilled {...rest} />;
    case 'IconStar':
      return <IconStar {...rest} />;
    case 'IconSteeringWheel':
      return <IconSteeringWheel {...rest} />;
    case 'IconSwitch':
      return <IconSwitch {...rest} />;
    case 'IconTablet':
      return <IconTablet {...rest} />;
    case 'IconThumbUp':
      return <IconThumbUp {...rest} />;
    case 'IconTrashBin':
      return <IconTrashBin {...rest} />;
    case 'IconTshirtSport':
      return <IconTshirtSport {...rest} />;
    case 'IconTwitter':
      return <IconTwitter {...rest} />;
    case 'IconUmbrella':
      return <IconUmbrella {...rest} />;
    case 'IconUpload':
      return <IconUpload {...rest} />;
    case 'IconUserCheck':
      return <IconUserCheck {...rest} />;
    case 'IconUserCircle':
      return <IconUserCircle {...rest} />;
    case 'IconUserCross':
      return <IconUserCross {...rest} />;
    case 'IconUserMinus':
      return <IconUserMinus {...rest} />;
    case 'IconUserPlus':
      return <IconUserPlus {...rest} />;
    case 'IconUser':
      return <IconUser {...rest} />;
    case 'IconUsers':
      return <IconUsers {...rest} />;
    case 'IconVan':
      return <IconVan {...rest} />;
    case 'IconVehicleCollisionKasko':
      return <IconVehicleCollisionKasko {...rest} />;
    case 'IconVehicleDriverInjuries':
      return <IconVehicleDriverInjuries {...rest} />;
    case 'IconVehicleFullKasko':
      return <IconVehicleFullKasko {...rest} />;
    case 'IconVehicleGlasses':
      return <IconVehicleGlasses {...rest} />;
    case 'IconVehicleLegalProtection':
      return <IconVehicleLegalProtection {...rest} />;
    case 'IconVehicleNaturalEvents':
      return <IconVehicleNaturalEvents {...rest} />;
    case 'IconVehicleRicourseWaiver':
      return <IconVehicleRicourseWaiver {...rest} />;
    case 'IconVehicleRoadsideAssistance':
      return <IconVehicleRoadsideAssistance {...rest} />;
    case 'IconVehicleSecuredBonus':
      return <IconVehicleSecuredBonus {...rest} />;
    case 'IconVehicleTheftFire':
      return <IconVehicleTheftFire {...rest} />;
    case 'IconVehicleVandalism':
      return <IconVehicleVandalism {...rest} />;
    case 'IconWallet':
      return <IconWallet {...rest} />;
    case 'IconWhatsapp':
      return <IconWhatsapp {...rest} />;
    case 'IconWrench':
      return <IconWrench {...rest} />;
    case 'IconYoutube':
      return <IconYoutube {...rest} />;
  }

  return null;
};

export default PyxisIcon;
